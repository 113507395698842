<svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 3C7.4 3 3.33333 8.33333 2 11C3.16667 13.8333 7 19.5 13 19.5C19 19.5 22.8333 13.8333 24 11C22.6667 8.33333 18.6 3 13 3Z" stroke="white" stroke-width="2"/>
    <mask id="mask0_0_1" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="5" width="10" height="11">
        <path d="M11 16L16 6L13 5.5L8 7L6.5 13L11 16Z" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_0_1)">
        <circle cx="13" cy="11" r="3.25" stroke="white" stroke-width="1.5"/>
    </g>
    <line x1="18.8944" y1="0.447214" x2="7.89443" y2="22.4472" stroke="white" stroke-width="2"/>
</svg>
