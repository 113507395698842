<script>

    import {onMount} from "svelte";
    import ShowIcon from "$lib/ShowIcon.svelte";
    import HideIcon from "$lib/HideIcon.svelte";

    export let invalid = false;
    export let border = false;
    export let focus = false;
    export let onEnter = () => {
    };
    export let value = '';
    export let isFilled = false;
    export let isPassword = false;
    export let disabled = false;
    export let light = false;
    let isPasswordVisible = false;
    let used = false;

    let input;

    $: invalidAndUsed = invalid && used;

    function checkFilled() {
        isFilled = value.length > 0 || input.matches(':autofill')
    }

    onMount(() => {
        if (focus) {
            input.focus();
        }
        if (isPassword) {
            input.type = 'password';
            isPasswordVisible = false;
        }
        setTimeout(checkFilled, 100);
    });

    function handleKeyDown(event) {
        if ((event.keyCode === 13 || event.key === 'Enter') && !disabled && !invalid) {
            onEnter(value);
        }
    }

    function handleChanged(event) {
        used = true;
        checkFilled();
    }

    function togglePasswordVisibility() {
        isPasswordVisible = !isPasswordVisible;
        input.type = isPasswordVisible ? 'text' : 'password';
    }

</script>

<div class="box" class:invalid={invalidAndUsed} class:border class:light>
    {#if $$slots.icon}
        <div class="icon">
            <slot name="icon"></slot>
        </div>
        <div class="divider" class:invalid={invalidAndUsed}></div>
    {/if}
    <div class="input">
        <input
                bind:this={input}
                bind:value
                class:invalid={invalidAndUsed}
                class:disabled
                on:keypress={handleKeyDown}
                on:change={handleChanged}
                on:input={handleChanged}
                on:paste={handleChanged}
                disabled={disabled}
                {...$$restProps}
        />
    </div>
    {#if isPassword}
        <button type="button" class="password-visibility" on:click|preventDefault={_=>togglePasswordVisibility()}>
            {#if isPasswordVisible}
                <HideIcon/>
            {:else}
                <ShowIcon/>
            {/if}
        </button>
    {/if}
</div>

<style lang="scss">
  .box {
    box-sizing: border-box;
    width: 100%;
    height: 49px;

    background: #363D63;
    border-radius: 8px;

    &.light {
      background: #fff;
    }

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.border {
      border: 1px solid #444D7C;
    }

    &.invalid {
      background: #6D3D43;
      border: 1px solid #B12D26;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      aspect-ratio: 1;

      :global(div) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
      }
    }

    .password-visibility {
      height: 100%;
      aspect-ratio: 1;
      background: transparent;
      border: none;
    }

    .divider {
      width: 0;
      height: 35px;
      border-left: 2px solid #444D7C;

      &.invalid {
        border-left: 2px solid #925259;
      }
    }

    .input {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #fff;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.33;
          animation: disableFade 0.2s;
        }

        &::placeholder {
          color: #B5A4FF;
        }

        &.invalid::placeholder {
          color: #F48F9A !important;
        }
      }

    }

    &.light .input input {
      color: #363D63 !important;
    }

  }
</style>